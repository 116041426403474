<template>
  <div id="SourceOfApple">
    <CSBreadcrumb/>
    <div class="result-panel">
        <CSTable :thead-top="60">
            <template v-slot:thead>
                <tr>
                    <th>获得时间</th>
                    <th>卖出数量</th>
                    <th>苹果来源</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="sellSourceData in sellSourceDataList"
                    :key="sellSourceData.id"
                >
                    <td class="full-date-td">{{sellSourceData.acquireTime}}</td>
                    <td>{{sellSourceData.amount}}</td>
                    <td>{{sellSourceData.action}}</td>
                </tr>
            </template>
        </CSTable>

    </div>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import {
  querySocialServiceSellSourceDataUrl
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
  name: "SourceOfApple",
  props: {
    id: Number,
  },
  components: {
      CSTable,
    CSBreadcrumb,
  },
  data() {
    return {
      sellSourceDataList: [],
    }
  },
  created() {
    this.getSellSourceData();
  },
  methods: {
    // 获取苹果卖出中的苹果来源数据
    async getSellSourceData() {
      let res = await this.$fly
          .get(querySocialServiceSellSourceDataUrl + "?exchangeId=" + this.id)
      if (res.code != 0) {
        return;
      }
      this.sellSourceDataList = res.data || [];
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
